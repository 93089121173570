class Accordion {
    constructor(elem, group = null, toggler = null) {
        this.toggler = (toggler) ? document.querySelector(toggler) : null;
        this.group = (group) ? elem.querySelector(group) : null;
        this.elem = document.querySelectorAll(elem);
        this.elem.forEach((item) => {
            item.addEventListener('click', (e) => {
                if (item.classList.contains('active')) {
                    this.slideUp(item);
                } else {
                    this.slideDown(item);
                }
            });
        });

        if (this.group && this.toggler) {
            this.toggler.addEventListener('click', (e) => {
                if (e.target.checked) {
                    this.toggleAllOn(this.group.querySelectorAll(this.elem));
                } else {
                    this.toggleAllOff(this.group.querySelectorAll(this.elem));
                }
            });
        }
    }

    slideDown(item) {
        item.querySelector('.panel, .sub-menu').style.maxHeight = `${item.querySelector('.panel,.sub-menu').scrollHeight}px`;
        item.classList.add('active');
        setTimeout(() => {
            item.querySelector('.panel,.sub-menu').style.maxHeight = `none`;
        }, 500);
    }
    
    slideUp(item) {
        item.classList.remove('active');
        item.querySelector('.panel,.sub-menu').style.maxHeight = `${item.querySelector('.panel,.sub-menu').scrollHeight}px`;
        setTimeout(() => {
            item.querySelector('.panel,.sub-menu').style.maxHeight = 0;
            item.classList.remove('active');
            this.toggler.checked = false;
        }, 1);
    }


    toggleAllOn(elems) {
        elems.forEach((item) => {
            this.slideDown(item);
        });
    }

    toggleAllOff() {
        elems.forEach((item) => {
            this.slideUp(item);
        });
    }

}


export default Accordion;
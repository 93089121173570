class Toggler{

    constructor(el) {
        this.el = document.querySelectorAll(el);
        if (this.el.length > 0) {
            this.setupEvents();
        }
    }

    setupEvents() {
        this.el.forEach(item => {
            item.addEventListener('click', (e) => {
                e.stopPropagation();
                document.body.classList.toggle(item.dataset.toggle)
            })            
        })
    }
}
export default Toggler;
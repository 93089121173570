class InView{
    constructor(el, options = {}, callback = null) {
        this.el = document.querySelectorAll(el);
        
        this.options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };
        
        if (callback) {
            this.callback = callback;
        }
        
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), this.options);
        this.el.forEach((item) => {
            this.observer.observe(item);
        });
    }

    handleIntersection(sections) {
        sections.forEach(section => {
            if (section.isIntersecting) 
            {
                this.callback(section.target);
            }
        });
    }


}


export default InView;
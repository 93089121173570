//import './scripts/custom';
import Accordion from "./components/accordion";
import Dragable from "./components/dragable";
import InView from "./components/inview";
import Toggler from "./components/toggler";
import Splide from '@splidejs/splide';


document.addEventListener('DOMContentLoaded', () => {
    
   new Toggler('[data-toggle]');
   
    new InView('.animated', {}, (el) => {
        el.classList.add('loaded');
    });

    new Accordion('.menu-item-has-children, .faq');

    const showcase = document.querySelector('.showcase'); 
	function cloneSlides(container, slides, count) {
		for (let i = 0; i < count; i++ ) {
			slides.forEach((slide) => {
				const clone = slide.cloneNode(true);
				container.appendChild(clone);
			})
		}
	}
	
	if (showcase) {
		const slides = showcase.querySelectorAll('figure');
		cloneSlides(showcase, slides, 3);
	}


    const splide = new Splide( '.timeline' ).mount();    
	splide.on('click', function(e) {
		splide.go(e.index)
	})

    
    
    function setupLinks(btn, inputs) {
        inputs[0].checked = true;
		btn.setAttribute('href', inputs[0].dataset.url);
		inputs.forEach((input) => {
			input.addEventListener('change', (e) => {
				btn.setAttribute('href', e.target.dataset.url)
			})
		});
	}

	const manButton = document.querySelector('#man-cta a');
	const manInputs = document.querySelectorAll('[name="man"]');

	if(manButton) {
		setupLinks(manButton, manInputs);
	}

});